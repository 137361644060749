/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, form, input, select, textarea {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@font-face {
  font-family: "FSME Bold";
  src: url("fonts/fsme-bold.ttf"); }

@font-face {
  font-family: "FSME Regular";
  src: url("fonts/fsme-regular.ttf"); }

/* . CORE . */
*,
*:before,
*:after {
  box-sizing: border-box; }

html,
body {
  width: 100%;
  min-width: 320px;
  color: #2D2D2D;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  position: relative;
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: auto;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important; }
  .lt-ie9 html, .lt-ie9
  body {
    min-width: 1280px; }

.l_grid {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  position: relative;
  padding: 0 15px; }
  .l_grid::after {
    clear: both;
    content: "";
    display: block; }

.l_columnGrid {
  margin: 1rem 0; }
  .l_columnGrid::after {
    clear: both;
    content: "";
    display: block; }
  .l_columnGrid .l_col {
    width: calc(100%);
    float: left;
    margin-left: 0;
    margin-bottom: 1rem; }
    @media (min-width: 768px) {
      .l_columnGrid .l_col {
        width: calc(50% - 24px);
        float: left;
        margin-left: 16px; }
        .l_columnGrid .l_col:first-child {
          padding-right: 3rem; } }

/* generated with grunt-sass-globbing */
.bg_color_white {
  background-color: #fff; }

.btn_reg {
  background-color: #043A58;
  color: #fff;
  border: none;
  cursor: pointer;
  display: block;
  float: right;
  font-family: "FSME Regular", sans-serif;
  font-size: 1rem;
  padding: 0.75rem 1rem; }
  .btn_reg::after {
    content: "\276F";
    color: #FCCE74;
    font-size: 0.6rem;
    font-weight: bold;
    margin-left: 0.25rem; }

.chk {
  align-items: center;
  display: flex;
  font-family: "FSME Regular", sans-serif;
  justify-content: space-between;
  color: #fff;
  font-size: 1.2em;
  padding: 0.75rem 1rem;
  width: 100%; }
  .chk label {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%; }
    .chk label input {
      appearance: none;
      -webkit-appearance: none;
      background-color: #fafafa;
      border: 1px solid #cacece;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 12px;
      border-radius: 3px;
      display: inline-block;
      outline: none;
      position: relative; }
      .chk label input:checked {
        background-color: #e9ecee;
        border: 1px solid #adb8c0;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
        color: #99a1a7; }
      .chk label input:checked:after {
        content: '\2714';
        font-size: 1.2rem;
        position: absolute;
        top: 0;
        left: 4px;
        color: #1BACB7; }
    .chk label:hover {
      cursor: pointer; }
  .chk .chk-mark {
    width: 20px; }
    .chk .chk-mark:after {
      font-size: 1.2rem;
      color: #fff;
      content: '\2714';
      top: 0; }

.error {
  background-color: #FFBABA;
  color: #D8000C;
  border: 1px solid #DBDBDB;
  display: block;
  float: right;
  font-family: "FSME Regular", sans-serif;
  font-size: 1rem;
  padding: 0.6rem 1rem; }

.iconpoint {
  display: flex;
  align-items: center; }
  .iconpoint .iconpoint-text {
    color: #1BACB7;
    font-family: "FSME Regular", sans-serif;
    margin-left: 2rem;
    margin-bottom: 0; }

h1, h2, h3 {
  font-family: "FSME Regular", sans-serif; }

h1 {
  color: #043A58;
  font-family: "FSME Regular", sans-serif;
  font-size: 2.2rem; }

h2 {
  color: #1BACB7;
  font-family: "FSME Bold", sans-serif;
  font-size: 1.4rem;
  margin-bottom: 1rem; }

h3 {
  font-family: "FSME Regular", sans-serif;
  font-size: 1.1rem;
  margin: 0 0 1rem 0; }

p {
  margin-bottom: 1rem; }

p.intro {
  font-family: "FSME Regular", sans-serif;
  font-size: 1.1rem;
  color: #1BACB7; }
  p.intro strong {
    font-family: "FSME Bold", sans-serif;
    font-size: 1.2rem; }

/* generated with grunt-sass-globbing */
.m_iconlist {
  margin: 2rem 0;
  padding-right: 3rem; }
  .m_iconlist li {
    margin-bottom: 1rem; }

.m_services {
  background-color: #F8F8F8;
  border: 1px solid #DBDBDB;
  color: #2D2D2D;
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  padding: 1rem;
  position: relative;
  text-align: left;
  width: 100%; }
  .m_services h3 {
    color: #1BACB7;
    font-size: 1.2rem;
    line-height: 1.25em;
    margin: 0 0 1rem 0; }
  .m_services section {
    margin-bottom: 1rem; }

/* generated with grunt-sass-globbing */
.o_cards {
  margin: 1rem 0; }
  .o_cards .ul::after {
    clear: both;
    content: "";
    display: block; }
  .o_cards .card {
    width: calc(100%);
    float: left;
    margin-left: 0;
    margin-bottom: 1rem; }
    @media (min-width: 768px) {
      .o_cards .card {
        width: calc(25% - 20px);
        float: left;
        margin-left: 16px; } }
    .o_cards .card.featured {
      width: calc(100%);
      float: left;
      margin-left: 0; }
      @media (min-width: 768px) {
        .o_cards .card.featured {
          width: calc(50% - 24px);
          float: left;
          margin-left: 16px; } }

.o_servicesList li {
  background-color: #00ACB8;
  color: #fff;
  margin-bottom: 1px;
  width: 100%; }

.o_servicesList.hasOptions {
  margin-bottom: 1rem; }
  .o_servicesList.hasOptions li {
    background-color: #7CD2D8; }
    .o_servicesList.hasOptions li:hover {
      background-color: #00ACB8;
      cursor: pointer; }
    .o_servicesList.hasOptions li.selected {
      background-color: #3EBFC8; }
